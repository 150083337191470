import React, { useEffect, useState } from "react";
import Web3 from "web3";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import MetamoneyContract from "../../contracts/Metamoney.json";
import "./burning.css";
import { ethers } from "ethers";
import { BsFillCircleFill } from "react-icons/bs";
import { BsCheckCircleFill } from "react-icons/bs";
import Font from "react-font";

export default function Burning() {
  const [defaultAccount, setDefaultAccount] = useState(null);
  const [provider, setProvider] = useState(null);
  const [signer, setSigner] = useState(null);
  const [contract, setContract] = useState(null);
  const [connectedNetwork, setConnectedNetwork] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");

  const [receivingAddress, setReceivingAddress] = useState("");
  const [userTokens, setUserTokens] = useState([]);
  const [selectedTokensValue, setSelectedTokensValue] = useState(0);

  const [loading, setLoading] = useState(true);

  let contractAddress = "0xdFbbB767b1cEb45438b06203EE3F34A613134a1e";

  useEffect(() => {
    connectWalletHandler();
    getNetwork();
  }, []);

  const getNetwork = async () => {
    let web3;
    if (window.ethereum) {
      web3 = new Web3(window.ethereum);
    } else if (window.web3) {
      web3 = new Web3(window.web3.currentProvider);
    }

    const currentNetwork = await web3.eth.net.getId();

    if (currentNetwork === 56) {
      setConnectedNetwork("BNB");
    }
  };

  window.ethereum.on("networkChanged", (networkId) => {
    if (networkId === "56") {
      setConnectedNetwork("BNB");
    } else {
      setConnectedNetwork("other");
    }
  });

  const connectWalletHandler = () => {
    if (window.ethereum && window.ethereum.isMetaMask) {
      window.ethereum
        .request({ method: "eth_requestAccounts" })
        .then((result) => {
          accountChangedHandler(result[0]);
        })
        .catch((error) => {
          setErrorMessage(error.message);
        });
    } else {
      console.log("Need to install MetaMask");
      setErrorMessage("Please install MetaMask browser extension to interact");
    }
  };

  const accountChangedHandler = (newAccount) => {
    setDefaultAccount(newAccount);
    updateEthers();
  };

  const updateEthers = () => {
    let tempProvider = new ethers.providers.Web3Provider(window.ethereum);
    setProvider(tempProvider);

    let tempSigner = tempProvider.getSigner();
    setSigner(tempSigner);

    let tempContract = new ethers.Contract(
      contractAddress,
      MetamoneyContract.abi,
      tempSigner
    );
    initiateGetUserTokenBalance(tempContract);
    setContract(tempContract);
    checkEvents();
  };

  const initiateGetUserTokenBalance = async (instance) => {
    const response = await instance.getUserTokenBalance();
    var userTokens = [];
    response.forEach((token) => {
      if (token.tokenId.toNumber() !== 0) {
        userTokens.push({
          id: token.tokenId.toNumber(),
          billValue: token.billValue.toNumber(),
          selected: false,
        });
      }
    });
    setUserTokens(userTokens);
    setLoading(false);
  };

  const toggleTokenSelected = (idx) => {
    const newUserTokens = [...userTokens];
    newUserTokens[idx].selected = !newUserTokens[idx].selected;
    setUserTokens(newUserTokens);

    var selectedTokensValue = 0;
    userTokens.forEach((userToken) => {
      if (userToken.selected) {
        selectedTokensValue = selectedTokensValue + userToken.billValue;
      }
    });
    setUserTokens(newUserTokens);
    setSelectedTokensValue(selectedTokensValue);
  };

  const displayUserTokens = userTokens.map((token, idx) => {
    return (
      <div className="tokenData-container" key={idx}>
       TOKEN ID: {token.id} &ensp;&ensp;VALUE: {token.billValue}{" "}&ensp;&ensp;
        {token.selected ? (
          <BsCheckCircleFill
            className="tokenData-icon"
            onClick={() => toggleTokenSelected(idx)}
          />
        ) : (
          <BsFillCircleFill
            className="tokenData-icon"
            onClick={() => toggleTokenSelected(idx)}
          />
        )}

      </div>
    );
  });

  const checkEvents = async () => {
    let tempProvider = new ethers.providers.Web3Provider(window.ethereum);
    let tempSigner = tempProvider.getSigner();
    const metamoneyContract = new ethers.Contract(
      contractAddress,
      MetamoneyContract.abi,
      tempSigner
    );

    metamoneyContract.on("TokenDeletion", (user, tokenId, index) => {
      console.log(user, tokenId.toNumber(), index.toNumber());
    });

    metamoneyContract.on("TokenDeletionLoopFinished", () => {
      console.log("TokenDeletionLoopFinished...");
      initiateGetUserTokenBalance(metamoneyContract);
    });

    metamoneyContract.on("TransferSent", (_to, _amount) => {
      console.log("BUSD transferred", _to, _amount.toNumber());
    });

    metamoneyContract.on(
      "TransferReceived",
      (_from, _amount, _BUSDtokenBalance) => {
        console.log(
          "BUSD transferred",
          _from,
          _amount.toNumber(),
          _BUSDtokenBalance.toNumber()
        );
      }
    );
  };

  const initiateBurn = async () => {
    setLoading(true);
    const userTokenIds = [];
    userTokens.forEach((token) => {
      if (token.selected) {
        userTokenIds.push(token.id);
      }
    });

    contract
      .burn(userTokenIds, receivingAddress)
      .then((res) => {
        console.log("res", res);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  return (
    <div className="burning-form">
      <Font family="Libre Baskerville">
        <div className="burning-form-title">Burning</div>
      </Font>
      <div className="burningForm-input-container">
        <label className="burningForm-label">Receiver Address</label>
        <input
          className="burningForm-input"
          type="text"
          onChange={(e) => {
            setReceivingAddress(e.target.value);
          }}
        />
      </div>
      {!loading ? (
        <div className="burning-form-container">
          {displayUserTokens}
          <div className="burning-form-price-container">
            BUSD: {selectedTokensValue}
          </div>
          {(selectedTokensValue !== 0) &
          (connectedNetwork === "BNB") &
          (receivingAddress !== "") ? (
            <button className="burning-form-button" onClick={initiateBurn}>
              Burn
            </button>
          ) : null}

          {connectedNetwork !== "BNB" && (
            <div className="connectedNetwork-message">
              Make sure you're connected to the Binance Smart Chain!
            </div>
          )}
        </div>
      ) : (
        <Spinner className="burning-form-spinner" animation="border" />
      )}
    </div>
  );
}
