import React, { useState } from "react";
import MetamaskButton from "../../components/MetamaskButton";
import Burning from "../burning/Burning";
import Title from "../title/Title";
import "./appContainer.css";
export default function AppContainer() {
  const [metamaskConnected, setMetamaskConnected] = useState(false);

  return (
    <div className="appContainer">
      {metamaskConnected ? (
        <Burning />
      ) : (
        <div className="metamaskButton-container">
          <MetamaskButton connect={(value) => setMetamaskConnected(value)} />
        </div>
      )}
    </div>
  );
}
